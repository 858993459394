<template>
  <div id="who-should-use-rubii-page">
    <div
      class="banner cvr-bg medium-title"
      style="background-image: url('/img/dummy/about/wsur/1.jpg');"
    >
      <div class="wrapper">
        <h1>{{ title }}</h1>
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of banner -->

    <div class="inner-sidebar">
      <div class="wrapper rowflex">
        <sidebar-about></sidebar-about>
        <article class="main wider">
          <div class="inner-wsur">
            <div class="hentry wider">
              <p>
                {{ description }}
              </p>
            </div>
            <!-- end of hentry -->

            <div class="list desktop">
              <div class="tb-item">
                <h2>Tenants / Buyers</h2>
                <table>
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <template v-for="(buyer, index) in buyers">
                        <th :key="index">
                          <figure>
                            <img :src="buyer.avatar" width="88" />
                            <figcaption>{{ buyer.name }}</figcaption>
                          </figure>
                        </th>
                      </template>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Group Name</td>
                      <template v-for="(buyer, index) in buyers">
                        <td :key="index">
                          <b>{{ buyer.groupName }}</b>
                        </td>
                      </template>
                    </tr>
                    <tr>
                      <td>Group Description</td>
                      <template v-for="(buyer, index) in buyers">
                        <td :key="index">
                          <div v-html="buyer.description"></div>
                        </td>
                      </template>
                    </tr>
                    <tr>
                      <td>How Can Rubii Help?</td>
                      <template v-for="(buyer, index) in buyers">
                        <td :key="index">
                          {{ buyer.review }}
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- end of tb item -->

              <div class="tb-item">
                <h2>Landlords / Sellers</h2>
                <table>
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <template v-for="(seller, index) in sellers">
                        <th :key="index">
                          <figure>
                            <img :src="seller.avatar" width="88" />
                            <figcaption>{{ seller.name }}</figcaption>
                          </figure>
                        </th>
                      </template>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Group Name</td>
                      <template v-for="(seller, index) in sellers">
                        <td :key="index">
                          <b>{{ seller.groupName }}</b>
                        </td>
                      </template>
                    </tr>
                    <tr>
                      <td>Group Description</td>
                      <template v-for="(seller, index) in sellers">
                        <td :key="index">
                          <div v-html="seller.description"></div>
                        </td>
                      </template>
                    </tr>
                    <tr>
                      <td>How Can Rubii Help?</td>
                      <template v-for="(seller, index) in sellers">
                        <td :key="index">
                          {{ seller.review }}
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- end of tb item -->
            </div>
            <!-- end of list desktop -->

            <div class="tablist">
              <h2>Tenants / Buyers</h2>
              <div class="tabhead">
                <template v-for="(buyer, index) in buyers">
                  <a
                    :key="index"
                    :class="{ active: selectedBuyer == index }"
                    @click="selectedBuyer = index"
                  >
                    <figure>
                      <img :src="buyer.avatar" width="48" />
                      <figcaption>{{ buyer.name }}</figcaption>
                    </figure>
                    <span>{{ buyer.groupName }}</span>
                  </a>
                </template>
              </div>
              <!-- end of tab head -->
              <template v-for="(buyer, index) in buyers">
                <div
                  :key="index"
                  class="tabitem"
                  :class="{ active: selectedBuyer == index }"
                >
                  <div class="tabholder">
                    <h3>Group Description</h3>
                    <div class="caption" v-html="buyer.description"></div>
                    <!-- end of caption -->
                  </div>
                  <div class="tabholder">
                    <h3>How Can Rubii Help?</h3>
                    <div class="caption">
                      <p>{{ buyer.review }}</p>
                    </div>
                    <!-- end of caption -->
                  </div>
                </div>
                <!-- end of tabitem -->
              </template>
            </div>
            <!-- end of tablist -->

            <div class="tablist">
              <h2>Landlords / Sellers</h2>
              <div class="tabhead">
                <template v-for="(seller, index) in sellers">
                  <a
                    :key="index"
                    :class="{ active: selectedSeller == index }"
                    @click="selectedSeller = index"
                  >
                    <figure>
                      <img :src="seller.avatar" width="48" />
                      <figcaption>{{ seller.name }}</figcaption>
                    </figure>
                    <span>{{ seller.groupName }}</span>
                  </a>
                </template>
              </div>
              <!-- end of tab head -->
              <template v-for="(seller, index) in sellers">
                <div
                  :key="index"
                  class="tabitem"
                  :class="{ active: selectedSeller == index }"
                >
                  <div class="tabholder">
                    <h3>Group Description</h3>
                    <div class="caption" v-html="seller.description"></div>
                    <!-- end of caption -->
                  </div>
                  <div class="tabholder">
                    <h3>How Can Rubii Help?</h3>
                    <div class="caption">
                      <p>{{ seller.review }}</p>
                    </div>
                    <!-- end of caption -->
                  </div>
                </div>
                <!-- end of tabitem -->
              </template>
            </div>
            <!-- end of tablist -->
          </div>
          <!-- end of innner wrp -->
        </article>
        <!-- end of inner waw -->
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of inner aside -->
  </div>
</template>

<script>
import SidebarAbout from "./SidebarAbout";

export default {
  name: "WhoShouldUseRubii",
  metaInfo: {
    title: "Who Should Use Rubii"
  },
  components: {
    SidebarAbout
  },
  data() {
    return {
      menuId: 3,
      title: "",
      banner: "",
      description: "",
      buyers: [],
      sellers: [],
      selectedBuyer: 0,
      selectedSeller: 0
    };
  },
  mounted() {
    this.getContent();
    this.getBuyerTestimonies();
    this.getSellerTestimonies();
  },
  methods: {
    getBuyerTestimonies() {
      this.$axios
        .get(`/about-us/testimonies`, {
          params: {
            lang: this.$store.state.locale.lang,
            page: 1,
            limit: 4,
            sortBy: "createdAt.desc",
            type: 1 // buyer
          }
        })
        .then(response => {
          this.buyers = response.data.data.rows;
        });
    },
    getSellerTestimonies() {
      this.$axios
        .get(`/about-us/testimonies`, {
          params: {
            lang: this.$store.state.locale.lang,
            page: 1,
            limit: 4,
            sortBy: "createdAt.desc",
            type: 2 // seller
          }
        })
        .then(response => {
          this.sellers = response.data.data.rows;
        });
    },
    getContent() {
      this.$axios
        .get(`/about-us/${this.menuId}`, {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          if (response.data.success) {
            this.title = response.data.data.title;
            this.banner = response.data.data.banner;
            this.description = response.data.data.descriptions;
          }
        });
    }
  }
};
</script>
